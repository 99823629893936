/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@2.2.4/dist/jquery.min.js
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/jquery-match-height@0.7.2/dist/jquery.matchHeight.min.js
 * - /npm/wow.js@1.2.2/dist/wow.min.js
 * - /npm/bootstrap@3.4.1/dist/js/bootstrap.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
